import {connect} from "react-redux";
import {Badge} from "antd";
import {useCallback, useEffect} from "react";
import apiService from "../../../services/ApiService";
import {setOpenRefunds} from "../../../redux/actions/Refunds";

/**
 * Open refunds counter badge
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const RefundBadge = (props) => {

    /**
     * Get non-approved items from API
     * @type {(function(): void)|*}
     */
    const getItems = useCallback(() => {
        apiService.getItems('refunds', {isApproved: 0}).then((result) => {
            if (props.setOpenRefunds) {
                props.setOpenRefunds(result['hydra:totalItems']);
            }
        })
    }, [props]);

    /**
     * Load open refunds when component did mount
     */
    useEffect(() => {
        getItems();
    }, [getItems])


    return <Badge count={props.count}>{props.children}</Badge>
}

const mapStateToProps = ({refunds}) => {
    const {count} = refunds;
    return {count}
};

export default connect(mapStateToProps, {setOpenRefunds})(RefundBadge);
