import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import {authenticated, signIn, signOut} from "../redux/actions/Auth";
import userService from "../services/UserService";
import {AUTH_SCOPE, AUTH_TOKEN, SIGNIN_AS} from "../redux/constants/Auth";
import {withBodyClass} from "../hooks/withBodyClass";
import apiService from "../services/ApiService";
import utils from "../utils";
import navigationConfig from "../configs/NavigationConfig";

class Views extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location !== this.props.location) {
            // set document title
            this.setDocumentTitle();
        }
    }

    /**
     * Load default user + token on page refresh
     */
    componentDidMount() {

        if (localStorage.getItem(AUTH_TOKEN) && !this.props.token) {
            this.props.authenticated(localStorage.getItem(AUTH_TOKEN));
        }

        if (this.props.token && !this.props.user) {

            // check if admin was logged in as user
            if (localStorage.getItem(SIGNIN_AS)) {

                // get sign in as profile
                apiService.getByRid(localStorage.getItem(SIGNIN_AS))
                    .then(async (user) => {
                        if (user) {

                            const role = userService.getRoleType(user.roles);
                            let scope = localStorage.getItem(AUTH_SCOPE);

                            let isValidScope = false;
                            // validate scope
                            // security for when someone changed the scope ID in the local storage
                            if (scope) {
                                const userScopes = user[role]
                                userScopes.forEach((s) => {
                                    if (s['@id'] === scope) {
                                        isValidScope = true;
                                    }
                                })
                            } else {
                                isValidScope = true;
                            }

                            if (!scope || !isValidScope) {
                                if (role === 'partner') {
                                    scope = user.partner[0]['@id'];
                                } else if (role === 'company') {
                                    scope = user.company[0]['@id'];
                                } else if (role === 'partnerChains') {
                                    scope = user.partnerChains[0]['@id'];
                                }
                            }

                            let company = null;
                            if (scope) {
                                company = await apiService.getByRid(scope)
                            }

                            this.props.signIn(user, scope, company);
                            this.setDocumentTitle()
                            this.setState({
                                isLoading: false
                            });
                        }
                    })

            } else {
                userService.getUser().then(res => {
                    apiService.getByRid(res.user).then((userData) => {

                        // validate user
                        if (userData.roles.length === 1 && userData.roles[0] === 'ROLE_USER') {
                            this.props.signOut();
                            window.location.reload();
                        }

                        let scope = localStorage.getItem(AUTH_SCOPE);
                        if (!scope && userData.partner.length) {
                            scope = userData.partner[0]['@id'];
                        }

                        this.props.signIn(userData, scope);
                        this.setDocumentTitle()
                        this.setState({
                            isLoading: false
                        });
                    })

                });
            }
        } else {
            this.setDocumentTitle()
            this.setState({
                isLoading: false
            });
        }

    }

    /**
     * Set document title based on the current page
     */
    setDocumentTitle() {
        let page = '';
        if (this.props.location) {
            if (this.props.user) {
                const role = userService.getRoleType(this.props.user.roles);
                const currentRouteInfo = utils.getRouteInfo(navigationConfig[role], this.props.location.pathname)
                if (currentRouteInfo) {
                    page = typeof currentRouteInfo.seo_title !== 'undefined' ? currentRouteInfo.seo_title : currentRouteInfo?.title;
                }
            }
        }
        if (page === '') {
            page = this.props.location.title ? this.props.location.title : this.props.location.pathname.split('/').pop();
        }

        const appName = 'Workit Portaal';
        if (page === '') {
            document.title = appName;
            return;
        }
        document.title = page + ' - Workit Portaal';
    }

    /**
     * Render view
     * @returns {JSX.Element|boolean}
     */
    render() {

        const {locale, direction, location} = this.props;
        const currentAppLocale = AppLocale[locale];

        // Redirect to login if not logged in
        if (!this.props.token && location.pathname.indexOf(AUTH_PREFIX_PATH) < 0) {
            return <Redirect to={`${AUTH_PREFIX_PATH}/login`}/>;
        }

        return (
            !this.state.isLoading && <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={APP_PREFIX_PATH}/>
                        </Route>
                        <Route path={AUTH_PREFIX_PATH}>
                            <AuthLayout direction={direction}/>
                        </Route>
                        <Route path={APP_PREFIX_PATH}>
                            <AppLayout direction={direction} location={location}/>
                        </Route>
                    </Switch>
                </ConfigProvider>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({theme, auth}) => {
    const {locale, direction} = theme;
    const {token, user} = auth;
    return {locale, token, direction, user}
};

const mapDispatchToProps = {
    authenticated,
    signIn,
    signOut
}

export default withBodyClass(withRouter(connect(mapStateToProps, mapDispatchToProps)(Views)));
