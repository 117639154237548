import axios from 'axios'
import {API_BASE_URL} from 'configs/AppConfig'
import history from '../history'
import {AUTH_TOKEN, SIGNIN_AS} from 'redux/constants/Auth'
import {notification} from 'antd';

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + jwtToken
    }

    config.headers['Accept-Language'] = 'nl';

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    let notificationParam = {
        message: ''
    }

    // Remove token and redirect
    if( typeof error.response !== 'undefined' && (error.response.data.message === "Expired JWT Token" || error.response.data.message === "Invalid JWT Token" )){
        // notificationParam.message = 'Sessie is verlopen'
        // notificationParam.description = 'Log opnieuw in om door te gaan'
        // notification.error(notificationParam);
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(SIGNIN_AS)
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    } else if (typeof error.response === 'undefined' || error.response.status === 403) {
        notificationParam.message = 'Er is een onbekende fout opgetreden'
        notificationParam.description = 'Probeer het later nog eens.'
    } else {
        if (error.response.status > 401 && error.response.status < 500) {
            notificationParam.message = error.response;
        }

        if (error.response.status === 500) {
            notificationParam.message = 'Internal Server Error'
        }

        if (error.response.status === 508) {
            notificationParam.message = 'Time Out'
        }

        // if( notificationParam.message ){
        //     notification.error(notificationParam);
        // }
    }


    return Promise.reject(error);
});

export default service
