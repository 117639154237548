import {Modal, Select} from "antd";
import {setScope} from "../../../redux/actions/Auth";
import {connect} from "react-redux";
import {useRef, useState} from "react";
import userService from "../../../services/UserService";

/**
 * Scope switcher
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ScopeSwitcher = props => {

    let options = [];
    const selectInput = useRef(null);
    const [selectedValue, setSelectedValue] = useState(props.scope);

    // check what to choose
    const role = userService.getRoleType(props.user?.roles);
    if (role === 'partner') {
        props.user.partner.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.companyName
            })
        })
    } else if (role === 'company') {
        props.user.company.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.name
            })
        })
    } else if (role === 'partnerChains') {
        props.user.partnerChains.forEach((row) => {
            options.push({
                value: row['@id'],
                label: row.companyName
            })
        })
    }

    return (options.length === 1 ?
        <strong>{options[0].label}</strong> :
        <Select style={{width: '100%', fontWeight: 'bold'}} className={"ml-n2"}
                options={options}
                ref={selectInput}
                onChange={value => {
                    Modal.confirm({
                        title: "Wisselen van bedrijf?",
                        content: "Weet je zeker dat je wilt wisselen van bedrijfsprofiel?",
                        confirmLoading: true,
                        onOk: () => {
                            props.setScope(value);
                            window.location.reload();
                        },
                        onCancel: () => {
                            setSelectedValue(props.scope);
                        }
                    });
                }}
                value={selectedValue}/>);
}


const mapStateToProps = ({auth}) => {
    const {user, scope} = auth;
    return {user, scope}
};
const mapDispatchToProps = {setScope}

export default connect(mapStateToProps, mapDispatchToProps)(ScopeSwitcher)