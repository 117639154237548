import React from 'react'
import {connect} from "react-redux";

/**
 * Footer component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Footer(props) {

    // check if logged in user is a company role user
    const isCompany = props.user?.roles.some((elm) => elm === "ROLE_COMPANY");

    return (
        <footer className="footer">
            <span>
                Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">Workit</span> All rights reserved.
            </span>
            {isCompany && <a href="/docs/algemene-voorwaarden-workit.pdf" target="_blank" rel="noreferrer">Algemene
                voorwaarden</a>}
        </footer>
    )
}

const mapStateToProps = ({auth}) => {
    const {user} = auth;
    return {user}
};
export default connect(mapStateToProps, null)(Footer);

