import {Divider, Skeleton} from "antd";
import {setScope} from "../../../redux/actions/Auth";
import {connect} from "react-redux";
import ApiService from "../../../services/ApiService";
import {useEffect, useState} from "react";
import ScopeSwitcher from "../ScopeSwitcher";
import {HomeOutlined} from "@ant-design/icons";

/**
 * Profile card component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileCard = props => {

    // define some states
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(true);

    // Load data when component did mount / updated
    useEffect(() => {
        if (props.scope) {
            ApiService.getByRid(props.scope, {}).then((response) => {
                if (typeof response.locations !== "undefined" && response.locations.length) {
                    const loc = response.locations[0];
                    const address = <div>
                        {loc.street + ' ' + loc.housenumber + (loc.housenumberAddon ? loc.housenumberAddon : '')}<br/>
                        {loc.postcode + ' ' + loc.city}
                    </div>;
                    setAddress(address);
                } else if(response.street) {
                    const address = <div>
                        {response.street + ' ' + response.housenumber + (response.housenumberAddon ? response.housenumberAddon : '')}<br/>
                        {response.postcode + ' ' + response.city}
                    </div>;
                    setAddress(address);
                }
                setLoading(false);
            })
        }
    }, [props.scope]);

    return (props.scope ? <div>
        <div className={"pl-4 pr-4 pt-4"}>
            {loading && <Skeleton/>}
            {!loading && props.collapsed ? <div className={"d-flex align-items-center justify-content-center"}><HomeOutlined /></div> : null}
            {!loading && !props.collapsed ? <div><ScopeSwitcher/>
                <div className={"mt-3"}>{address}</div>
            </div> : null}
        </div>
        <Divider className={'mb-0'}/>
    </div> : null)
}

const mapStateToProps = ({auth}) => {
    const {scope} = auth;
    return {scope}
};
const mapDispatchToProps = {setScope}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)