import {
    REFUNDS_OPEN
} from "../constants/Refunds";

export const setOpenRefunds = (count) => {
    return {
        type: REFUNDS_OPEN,
        count
    }
}
