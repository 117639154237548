import {
    ApiOutlined,
    BookOutlined,
    CalendarOutlined,
    ClusterOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    EuroOutlined,
    GiftOutlined,
    HomeOutlined,
    IdcardOutlined,
    ImportOutlined,
    LockOutlined,
    PaperClipOutlined,
    RocketOutlined,
    ScheduleOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
    TrophyOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import RefundBadge from "../components/shared-components/RefundBadge";

const navigationConfig = {
    "admin": [
        {
            key: 'home',
            component: import('../views/admin-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: 'profile_edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'profile_edit',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: false,
            in_menu: false,
            submenu: []
        },
        {
            key: 'bookings',
            component: import('../views/admin-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'bookings',
            seo_title: 'Boekingen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'partners',
            component: import('../views/admin-views/partners/index'),
            path: `${APP_PREFIX_PATH}/partners`,
            title: 'partners',
            seo_title: 'Partners',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'partners_add',
                    component: import('../views/admin-views/partners/add'),
                    path: `${APP_PREFIX_PATH}/partners/add`,
                    title: 'partners.add',
                    seo_title: 'Partner toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'partners_edit',
                    component: import('../views/admin-views/partners/edit'),
                    path: `${APP_PREFIX_PATH}/partners/edit/:id`,
                    title: 'partners.edit',
                    seo_title: 'Partner bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'partners_edit',
                    component: import('../views/admin-views/lesson-overwrite-column-value'),
                    path: `${APP_PREFIX_PATH}/partners/:id/lesson-overwrite-column-values`,
                    title: 'lessonOverwriteColumnValue.title',
                    seo_title: 'Lesconfiguratie',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'partnerChains',
            component: import('../views/admin-views/partner-chains/index'),
            path: `${APP_PREFIX_PATH}/partner-chains`,
            title: 'partnerChains.title',
            seo_title: 'Sportlocatie-ketens',
            icon: ClusterOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'partners_add',
                    component: import('../views/admin-views/partner-chains/add'),
                    path: `${APP_PREFIX_PATH}/partner-chains/add`,
                    title: 'partnerChains.add',
                    seo_title: 'Keten toevoegen',
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'partners_edit',
                    component: import('../views/admin-views/partner-chains/edit'),
                    path: `${APP_PREFIX_PATH}/partner-chains/edit/:id`,
                    title: 'partnerChains.edit',
                    seo_title: 'Keten bewerken',
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },

            ]
        },
        {
            key: 'lessons',
            component: import('../views/admin-views/lessons/index'),
            path: `${APP_PREFIX_PATH}/lessons`,
            title: 'lessons',
            seo_title: 'Lessen',
            icon: TrophyOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'lessons_add',
                    component: import('../views/admin-views/lessons/add'),
                    path: `${APP_PREFIX_PATH}/lessons/add`,
                    title: 'lessons.add_new',
                    seo_title: 'Les toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'lessons_edit',
                    component: import('../views/admin-views/lessons/edit'),
                    path: `${APP_PREFIX_PATH}/lessons/edit/:id`,
                    title: 'lessons.edit',
                    seo_title: 'Les bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'lessons_type_mapping',
                    component: import('../views/admin-views/lesson-type-mapping/index'),
                    path: `${APP_PREFIX_PATH}/lessons/types/mapping`,
                    title: 'lessons.mapping',
                    seo_title: 'Lestypes',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'lessons_types',
                    component: import('../views/admin-views/lessons/types'),
                    path: `${APP_PREFIX_PATH}/lessons/types`,
                    title: 'lessons.types',
                    seo_title: 'Lestypes',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
            ]
        },
        {
            key: 'locations',
            component: import('../views/admin-views/locations/index'),
            path: `${APP_PREFIX_PATH}/locations`,
            title: 'locations',
            seo_title: 'Locaties',
            icon: BookOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'locations_add',
                    component: import('../views/admin-views/locations/add'),
                    path: `${APP_PREFIX_PATH}/locations/add`,
                    title: 'locations.add',
                    seo_title: 'Locatie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'locations_edit',
                    component: import('../views/admin-views/locations/edit'),
                    path: `${APP_PREFIX_PATH}/locations/edit/:id`,
                    title: 'locations.edit',
                    seo_title: 'Locaties bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'users',
            component: import('../views/admin-views/users/index'),
            path: `${APP_PREFIX_PATH}/users`,
            title: 'users',
            seo_title: 'Gebruikers',
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'users_edit',
                    component: import('../views/admin-views/users/edit'),
                    path: `${APP_PREFIX_PATH}/users/edit/:id`,
                    title: 'users.edit',
                    seo_title: 'Gebruiker bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'user_subscriptions',
                    component: import('../views/admin-views/users/subscriptions'),
                    path: `${APP_PREFIX_PATH}/users/subscriptions/:id`,
                    title: 'users.subscriptions',
                    seo_title: 'Abonnementen bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
            ]
        },
        {
            key: 'companies',
            component: import('../views/admin-views/companies/index'),
            path: `${APP_PREFIX_PATH}/companies`,
            title: 'companies',
            seo_title: 'Bedrijven',
            icon: IdcardOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'companies_add',
                    component: import('../views/admin-views/companies/add'),
                    path: `${APP_PREFIX_PATH}/companies/add`,
                    title: 'companies.add_new',
                    seo_title: 'Bedrijf toevoegen',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'companies_edit',
                    component: import('../views/admin-views/companies/edit'),
                    path: `${APP_PREFIX_PATH}/companies/edit/:id`,
                    title: 'companies.edit',
                    seo_title: 'Bedrijf bewerken',
                    icon: IdcardOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'finances',
            component: null,
            title: 'finances',
            seo_title: 'Financiën',
            icon: CreditCardOutlined,
            breadcrumb: true,
            in_menu: true,
            badge: <RefundBadge/>,
            submenu: [
                {
                    key: 'refunds',
                    component: import('../views/admin-views/refunds/index'),
                    path: `${APP_PREFIX_PATH}/finances/refunds`,
                    title: 'refunds',
                    seo_title: 'Cashbacks',
                    icon: GiftOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    badge: <RefundBadge/>,
                    submenu: []
                },
                {
                    key: 'invoices_import',
                    component: import('../views/admin-views/invoices/importSpecifications'),
                    path: `${APP_PREFIX_PATH}/finances/invoices/import`,
                    title: 'invoices.import_specifications',
                    seo_title: 'Specificaties importeren',
                    icon: ImportOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'invoices',
                    component: import('../views/admin-views/invoices/index'),
                    path: `${APP_PREFIX_PATH}/finances/invoices`,
                    title: 'invoices.title',
                    seo_title: 'Specificaties',
                    icon: EuroOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: []
                },
                {
                    key: 'payments',
                    component: import('../views/admin-views/payments/index'),
                    path: `${APP_PREFIX_PATH}/finances/payments`,
                    title: 'payments.title',
                    seo_title: "Betalingen",
                    icon: CreditCardOutlined,
                    breadcrumb: true,
                    in_menu: true,
                    submenu: []
                }
            ]
        },
        {
            key: 'subscriptions',
            component: import('../views/admin-views/subscriptions/index'),
            path: `${APP_PREFIX_PATH}/subscriptions`,
            title: 'subscriptions',
            seo_title: 'Abonnementen',
            icon: ShoppingCartOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'subscriptions_add',
                    component: import('../views/admin-views/subscriptions/add'),
                    path: `${APP_PREFIX_PATH}/subscriptions/add`,
                    title: 'subscriptions.add',
                    seo_title: 'Abonnement toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'subscriptions_edit',
                    component: import('../views/admin-views/subscriptions/edit'),
                    path: `${APP_PREFIX_PATH}/subscriptions/edit/:id`,
                    title: 'subscriptions.edit',
                    seo_title: 'Abonnement bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'administrators',
            component: import('../views/admin-views/administrators/index'),
            path: `${APP_PREFIX_PATH}/administrators`,
            title: 'administrators',
            seo_title: 'Administrators',
            icon: LockOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'administrators_add',
                    component: import('../views/admin-views/administrators/add'),
                    path: `${APP_PREFIX_PATH}/administrators/add`,
                    title: 'users.add_new',
                    seo_title: 'Administrator toevoegen',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'administrators_edit',
                    component: import('../views/admin-views/administrators/edit'),
                    path: `${APP_PREFIX_PATH}/administrators/edit/:id`,
                    title: 'users.edit',
                    seo_title: 'Administrator bewerken',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'connectors',
            component: import('../views/admin-views/connectors/index'),
            path: `${APP_PREFIX_PATH}/connectors`,
            title: 'connectors',
            seo_title: 'Connectoren',
            icon: ApiOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'connectors_add',
                    component: import('../views/admin-views/connectors/add'),
                    path: `${APP_PREFIX_PATH}/connectors/add`,
                    title: 'connectors.add',
                    seo_title: 'Connector toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'connectors_edit',
                    component: import('../views/admin-views/connectors/edit'),
                    path: `${APP_PREFIX_PATH}/connectors/edit/:id`,
                    title: 'connectors.edit',
                    seo_title: 'Connector bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'news',
            component: import('../views/admin-views/news/index'),
            path: `${APP_PREFIX_PATH}/news`,
            title: 'news',
            seo_title: 'News',
            icon: PaperClipOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: [
                {
                    key: 'news_add',
                    component: import('../views/admin-views/news/add'),
                    path: `${APP_PREFIX_PATH}/news/add`,
                    title: 'news.add',
                    seo_title: 'Nieuws toevoegen',
                    icon: PaperClipOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'news_edit',
                    component: import('../views/admin-views/news/edit'),
                    path: `${APP_PREFIX_PATH}/news/edit/:id`,
                    title: 'news.edit',
                    seo_title: 'Nieuws bewerken',
                    icon: PaperClipOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
    ],
    "partner": [
        {
            key: 'home',
            component: import('../views/partner-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: "schedules",
            component: import("../views/partner-views/schedules/index"),
            path: `${APP_PREFIX_PATH}/schedules`,
            title: "schedules",
            seo_title: 'Roosters',
            icon: ScheduleOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: "schedules_add",
                    component: import("../views/partner-views/schedules/add"),
                    path: `${APP_PREFIX_PATH}/schedules/add`,
                    title: "schedules.add",
                    seo_title: 'Rooster toevoegen',
                    icon: ScheduleOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                },
                {
                    key: "schedules_edit",
                    component: import("../views/partner-views/schedules/edit"),
                    path: `${APP_PREFIX_PATH}/schedules/edit/:id`,
                    title: "schedules.edit",
                    seo_title: 'Rooster bewerken',
                    icon: ScheduleOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: [],
                }
            ],
        },
        {
            key: "lessons",
            component: import("../views/partner-views/lessons/index"),
            path: `${APP_PREFIX_PATH}/lessons`,
            title: "lessons",
            seo_title: 'Lessen',
            icon: RocketOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: 'lessons_add',
                    component: import('../views/partner-views/lessons/add'),
                    path: `${APP_PREFIX_PATH}/lessons/add`,
                    title: 'lessons.add',
                    seo_title: 'Les toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'lessons_edit',
                    component: import('../views/partner-views/lessons/edit'),
                    path: `${APP_PREFIX_PATH}/lessons/edit/:id`,
                    title: 'lessons.edit',
                    seo_title: 'Les bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ],
        },
        {
            key: 'bookings',
            component: import('../views/partner-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'bookings',
            seo_title: 'Boekingen',
            icon: TeamOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'closed-days',
            component: import('../views/partner-views/closed-days/index'),
            path: `${APP_PREFIX_PATH}/closed-days`,
            title: 'closed-days',
            seo_title: 'Gesloten dagen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_SCHEDULER'],
            },
            submenu: [
                {
                    key: 'closed-days_add',
                    component: import('../views/partner-views/closed-days/add'),
                    path: `${APP_PREFIX_PATH}/closed-days/add`,
                    title: 'closed-days.add_new',
                    seo_title: 'Gesloten dag toevoegen',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'closed-days_edit',
                    component: import('../views/partner-views/closed-days/edit'),
                    path: `${APP_PREFIX_PATH}/closed-days/edit/:id`,
                    title: 'closed-days.edit',
                    seo_title: 'Gesloten dag bewerken',
                    icon: LockOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
            ]
        },
        {
            key: 'company-data-edit',
            component: import('../views/partner-views/profile/company'),
            path: `${APP_PREFIX_PATH}/partner/edit`,
            title: 'company-data',
            seo_title: 'Bedrijfsgegevens',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER'],
            },
            submenu: []
        },
        {
            key: 'invoices',
            component: import('../views/shared-views/invoices/index'),
            path: `${APP_PREFIX_PATH}/invoices`,
            title: 'invoices',
            seo_title: 'Specificaties',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            security: {
                allow: ['ROLE_PARTNER', 'ROLE_PARTNER_FINANCE'],
            },
            submenu: []
        },
        {
            key: 'profile-edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'profile-edit',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: []
        },
        {
            key: 'locations',
            component: import('../views/partner-views/locations/index'),
            path: `${APP_PREFIX_PATH}/locations`,
            title: 'locations',
            seo_title: 'Locaties',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: [
                {
                    key: 'locations_add',
                    component: import('../views/partner-views/locations/add'),
                    path: `${APP_PREFIX_PATH}/locations/add`,
                    title: 'locations.add',
                    seo_title: 'Locatie toevoegen',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                },
                {
                    key: 'locations_edit',
                    component: import('../views/partner-views/locations/edit'),
                    path: `${APP_PREFIX_PATH}/locations/edit/:id`,
                    title: 'locations.edit',
                    seo_title: 'Locatie bewerken',
                    icon: HomeOutlined,
                    breadcrumb: true,
                    in_menu: false,
                    submenu: []
                }
            ]
        },
        {
            key: 'connectors',
            component: import('../views/partner-views/connectors/index'),
            path: `${APP_PREFIX_PATH}/connectors`,
            title: 'connectors',
            seo_title: 'Softwarepartijen',
            icon: ApiOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: []
        },
        {
            key: 'settings',
            component: import('../views/partner-views/settings/index'),
            path: `${APP_PREFIX_PATH}/settings`,
            title: 'settings',
            seo_title: 'Instellingen',
            icon: SettingOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: []
        }
    ],
    "company": [
        {
            key: 'home',
            component: import('../views/company-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: 'users',
            component: import('../views/company-views/users/index'),
            path: `${APP_PREFIX_PATH}/users`,
            title: 'participants',
            seo_title: 'Deelnemers',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'company',
            component: import('../views/company-views/company/edit'),
            path: `${APP_PREFIX_PATH}/company`,
            title: 'my_company',
            seo_title: 'Bedrijfsgegevens',
            icon: HomeOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'profile-edit',
            component: import('../views/shared-views/profile/index'),
            path: `${APP_PREFIX_PATH}/profile/edit`,
            title: 'profile-edit',
            seo_title: 'Profiel bewerken',
            icon: UserOutlined,
            breadcrumb: true,
            in_menu: false,
            submenu: []
        },
    ],
    "partnerChains": [
        {
            key: 'home',
            component: import('../views/partner-chain-views/home/index'),
            path: `${APP_PREFIX_PATH}/home`,
            title: 'home',
            seo_title: 'Dashboard',
            icon: DashboardOutlined,
            breadcrumb: false,
            in_menu: true,
            submenu: []
        },
        {
            key: 'sales',
            component: import('../views/partner-chain-views/sales/index'),
            path: `${APP_PREFIX_PATH}/sales`,
            title: 'sales',
            seo_title: 'Omzet',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'bookings',
            component: import('../views/partner-chain-views/bookings/index'),
            path: `${APP_PREFIX_PATH}/bookings`,
            title: 'bookings',
            seo_title: 'Boekingen',
            icon: CalendarOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
        {
            key: 'invoices',
            component: import('../views/shared-views/invoices/index'),
            path: `${APP_PREFIX_PATH}/invoices`,
            title: 'invoices',
            seo_title: 'Specificaties',
            icon: EuroOutlined,
            breadcrumb: true,
            in_menu: true,
            submenu: []
        },
    ]
};

export default navigationConfig;
